import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CompaniesService } from './modules/companies/services/companies.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SplashScreenModule } from './modules/layouts/splash-screen/splash-screen.module';
import { DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ExternalAuthService } from './auth/external-auth.service';
import { ExternalAuthGuard } from './auth/external-auth.guard';
import { ExternalAuthInterceptor } from './auth/external-auth-interceptor.service';
import { SharedLibraryModule } from './pages/main-page/shared-library/shared-library.module';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function appInitializer(externalAuthGuard: ExternalAuthGuard) {
  return () => { };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InlineSVGModule.forRoot(),
    SplashScreenModule,
    TranslateModule.forRoot(),
    SharedLibraryModule
  ],
  providers: [
    ExternalAuthService,
    DecimalPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ExternalAuthGuard, CompaniesService, Location, ActivatedRoute],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExternalAuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
