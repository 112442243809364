import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | undefined): unknown {
    let result = value
      ?.toLocaleString('en-US')
      .replace(/,/g, '+');
    result = result?.replace(/\./g, ',');
    result = result?.replace(/\+/g, '.');

    return result;
  }
}
