import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from './models/TableColumn';

export const columnTypes = {
  regular: 'regular',
  link: 'link',
  date: 'date',
  iconLink: 'icon-link',
  iconAction: 'icon-action',
  iconMultiAction: 'icon-multi-action',
  toggleSvgIcon: 'toggle-svg-icon',
  regularWMulticolorIcons: 'regular-w-multicolor-icon',
  selection: 'selection',
  combination: 'combination',
  checkbox: 'checkbox',
  detailed: 'detailed'
};

export interface Action {
  actionType?: string;
  actionTitle: string;
  icon?: string;
  link?: string;
  conditional?: boolean;
  fieldOfCondition: string;
  svgPath?: string;
  fieldOfRedirection: string;
}
export interface TableActionPayload {
  action: string;
  payload: any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() columns: TableColumn[] = [];
  @Input() dataSource?: any = [];
  @Input() largeTable?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() tableCaption?: string = '';
  @Input() pageSize = 5;
  @Input() selection?: SelectionModel<any>;
  @Output() actionClicked = new EventEmitter<TableActionPayload>(true);
  displayedColumns?: Array<any>;
  columnTypes = columnTypes;
  allSelected = false;

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(
      (tableColumn: TableColumn) => tableColumn.caption
    );
  }

  actClicked(action: any, payload: any) {
    this.actionClicked.emit({ action, payload });
  }

  handleSelection(element: any) {
    this.selection?.toggle(element);
    this.isAllSelected();
  }

  masterToggle() {
    if (this.allSelected) {
      this.selection?.clear();
    } else {
      this.dataSource.forEach((row: any) => this.selection?.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection?.selected.length;
    const numRows = this.dataSource.length;
    this.allSelected = numSelected === numRows;
  }
}
