// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  develop: true,
  hmr: false,
  appVersion: 'v717dev1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  userDataKey: 'authf649fc9a5f55',
  isMockEnabled: false,
  authUrl: 'https://auth.qa.brevity.pro/connect/',
  authLogin: 'https://auth.qa.brevity.pro/Login',
  apiUrl: 'https://api.qa.brevity.pro/api/',
  apiPdfUrl: 'https://generator.qa.brevity.pro/',
  apiUrlDownloads: 'https://api.qa.brevity.pro/api/',
  amplitudeApiKey: 'b7cf769514216bfa465046c6a3735e68',
  ppdURL:'https://pdd.qa.brevity.pro/',
  /*  authUrl: 'http://localhost:5001/connect/',
  apiUrl: 'http://localhost:5000/api/',
  apiPdfUrl: 'https://generator.brevity.pro/',
  apiUrlDownloads: 'http://localhost:5000/api/'*/
 };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
