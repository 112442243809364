import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsPageComponent } from './documents-page/documents-page.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DocumentsPageComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
})
export class SharedLibraryModule {}
