/* eslint-disable @typescript-eslint/naming-convention */

export const Spanish = {
  lang: 'ES',
  data: {
    TITLES: {
      COMPANIES: 'Sociedades',
      FOLLOWERS: 'Seguidores',
      ORIGIN_COUNTRY: 'País de origen',
      SHARE_INFO: 'Compartir Información',
      LIBRARY: 'Biblioteca',
      EXPIRATIONS: 'Vencimientos',
      USERS: 'Usuarios',
    },
    TABS: {
      GENERAL_INFO: 'Información general',
      EXPIRATIONS: 'Vencimientos',
      POWERS: 'Poderes',
      STATUTE: 'Estatuto',
      STATUTES: 'Estatutos',
      DOCS: 'Formation documents',
    },
    LABELS: {
      COMPANY_NAME: 'Nombre de la Sociedad',
      COMPANY_TYPE: 'Tipo de Sociedad',
      CORPORATE_DATA_CONTROL: 'Control de Datos Societarios',
      DOC_TYPE: 'Tipo de documento',
      DOC_NAME: 'Nombre del documento',
      DOC_DATE: 'Fecha del documento',
      PROCEDURE_TYPE: 'Tipo de trámite',
      SELECT_DOCS: 'Seleccionar Documento(s)',
      SELECT_DOC: 'Seleccionar Documento',
      SELECT_PROCEDURES: 'Select procedure type',
      VERIFICATION_STATUS: 'Estado de Verificación',
      VERIFIED_BY: 'Verificado por',
      NOT_VERIFY: 'No verificado',
      VERIFY: 'Verificado',
      LAST_VERIFICATION: 'Última verificación',
      LAST_MODIFICATION: 'Última modificación',
      MODIFIED_BY: 'Modificado por',
      PREVIEW: 'Vista Previa',
      LOADING: 'Cargando',
      COMPANIES: 'Sociedades',
      COMPANY: 'Sociedad',
      EDIT: 'Editar',
      DELETE: 'Eliminar',
      SELECT: 'Seleccionar',
      USER: 'Usuario',
      DOCUMENT_TYPE: 'Tipo de documento',
      NAME: 'Nombre',
      LAST_NAME: 'Apellido',
      PHONE: 'Teléfono',
      EMAIL: 'Email',
      ACTIONS: 'Acciones',
      FOLDER: 'Carpeta',
      CREATE: 'Crear',
      EXPIRATION_DATE: 'Fecha de vencimiento',
      EXPIRATION_TYPE: 'Tipo de vencimiento',
      EXPIRATION_STATUS: 'Estado del vencimiento',
      EXPIRATION: 'Vencimiento',
      AUTHORITY: 'Autoridad'
    },
    BUTTONS: {
      UPDATE: 'Actualizar',
      GO_TO_LIBRARY: 'Ir a la biblioteca',
      CORPORATE_SUMMARY: 'Ver Reporte',
      VERIFY: 'Verificar',
      SHARE: 'Compartir',
      ATTACH_DOCS: 'Documentos Vinculados',
      SAVE: 'Guardar',
      MODIFY: 'Modificar',
      NEXT: 'Siguiente',
      CHANGE_COUNTRY: 'Cambiar de País',
      CLOSE: 'Cerrar',
      NEW: 'Nuevo',
      NUEVA: 'Nueva',
      DOC: 'Documento',
      DOCS: 'Documentos',
      BY_DOC_NAME: 'Por nombre del documento',
      BY_FOLDER_NAME: 'Por nombre de carpeta',
      BY_CONTENT: 'Por contenido del documento',
      UPLOAD: 'Cargar',
      DELETE: 'Eliminar',
      DOWNLOAD: 'Descargar',
      MOVE_TO: 'Mover a',
      ADD: 'Agregar',
      ALL: 'Todos',
      CANCEL: 'Cancelar',
      RESOLVED: 'Resuelto',
    },
    PLACEHOLDERS: {
      SEARCH: 'Buscar',
      USERS: 'Usuarios',
      SEARCH_USER: 'Buscar usuario',
      SEARCH_COMPANY: 'Buscar sociedad',
    },

    MENU: {
      LIBRARY: 'Biblioteca',
      DOCUMENTS: 'Documentos',
      PERSONS: 'Personas',
      COMPANIES: 'Sociedades',
      TRAMITES: 'Trámites a Registar',
      EXPIRATIONS: 'Alertas y Vencimientos',
      SHAREINFO: 'Compartir Información',
      ADD: 'Crear',
    },
    PROFILE: {
      LOGOUT: 'Salir',
      USERS_SETTINGS: 'Configuración de usuarios',
    },
    TABLES: {
      EMPTY: 'No hay registros',
    },
    TEXT: {
      CHOOSE_COUNTRY: 'Elija el país correspondiente a la sociedad',
      ADD_CUSTOM_EXPIRATION: 'Agregar vencimiento personalizado',
      EDIT_CUSTOM_EXPIRATION: 'Editar vencimiento personalizado',
      INVALID_SELECTION: 'Seleccion inválida',
      SURE_TO_DELETE: '¿Seguro de desea eliminar el vencimiento?',
      SELECT_MODULE_ERROR: 'Seleccione al menos un módulo',
    },

    // SETTING BY COUNTRY CODE
    BY_COUNTRY: {
      AR: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
        },
      },
      DF: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          FOREING_ADDRESS: 'Domicilio',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
        },
      },
      CL: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          TYPE: 'Sede Social',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
        },
      },
      PY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUC',
          LEGAL_DOCUMENT_TYPE: 'RUC',
          STREET: 'Calle',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Calle',
          TYPE: 'Domicilio',
          PROVINCE: 'Departamento',
          LOCATION: 'Ciudad',
          FOREING: 'Domicilio Local',
        },
      },
      US: {
        INFO: {
          DOCUMENT_TYPE: 'TAX number',
          FILLING_NUMBER: 'Filling Number',
          REGISTERED_AGENT: 'Registered Agent',
        },
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
        UBICATION: {
          ADDRESS: 'Domicilio',
          TYPE: 'Sede Social',
          FOREING_ADDRESS: 'Principal place of business',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
        },
      },
      UY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'No. de puerta',
          ADDRESS_NUMBER: 'No. de Apartamento',
        },
        UBICATION: {
          TYPE: 'Domicilio',
          PROVINCE: 'Departamento',
          LOCATION: 'Localidad',
          FOREING: 'Domicilio',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
      },
    },
  },
};
