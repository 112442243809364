import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {
  Company,
  CompanyPermission,
  CompanyResponse,
  SectionsId,
} from '../models/company';
import { GeneralInformationResponse } from '../models/general-information';
import { AddressInformationResponse } from '../models/address';
import { ShareholderInformationResponse } from '../models/shareholder';
import { environment } from '../../../../environments/environment';
import { RequestResponse } from '../../../core/models/api-result.model';
import { PowerInformationResponse } from '../models/power';
import { StatuteInformationResponse } from '../models/statute';
import { AutorityInformationResponse } from '../models/autority';
import { RepresentativesInformationResponse } from '../models/representatives';
import { ContratoInformationResponse } from '../models/contrato';
import { BalanceInformationResponse } from '../models/balance';
import { BienesInformationResponse } from '../models/bienes';
import { FormationInformationResponse } from '../models/formation';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  companySelected?: Company;
  private companiesSubject: Subject<RequestResponse<CompanyResponse[]>> =
    new Subject<RequestResponse<CompanyResponse[]>>();
  private loadingCompanies = false;

  constructor(private httpClient: HttpClient) {}

  getCompanies() {
    if (!this.loadingCompanies) {
      this.loadingCompanies = true;
      this.httpClient
        .get<RequestResponse<CompanyResponse[]>>(
          `${environment.apiUrl}portal/index`
        )
        .subscribe((newData) => {
          this.loadingCompanies = false;
          this.companiesSubject.next(newData);
        });
    }
    return this.companiesSubject;
  }

  setCompany(company: Company) {
    this.companySelected = company;
  }

  getCompany() {
    return this.companySelected;
  }
  getGeneralInfo(): Observable<RequestResponse<GeneralInformationResponse>> {
    return this.httpClient.get<RequestResponse<GeneralInformationResponse>>(
      `${environment.apiUrl}portal/information/${this.companySelected?.id}`
    );
  }

  getAddressInfo() {
    return this.httpClient.get<RequestResponse<AddressInformationResponse[]>>(
      `${environment.apiUrl}portal/address/${this.companySelected?.id}`
    );
  }

  getShareHolderInfo() {
    return this.httpClient.get<
      RequestResponse<ShareholderInformationResponse[]>
    >(`${environment.apiUrl}portal/company/${this.companySelected?.id}/type/3`);
  }

  getRepresentativesInfo() {
    return this.httpClient.get<
      RequestResponse<RepresentativesInformationResponse[]>
    >(
      `${environment.apiUrl}portal/company/${this.companySelected?.id}/type/13`
    );
  }

  getAutorityInfo() {
    return this.httpClient.get<RequestResponse<AutorityInformationResponse[]>>(
      `${environment.apiUrl}portal/company/${this.companySelected?.id}/type/1`
    );
  }

  getPowerInfo() {
    return this.httpClient.get<RequestResponse<PowerInformationResponse[]>>(
      `${environment.apiUrl}portal/powers/${this.companySelected?.id}`
    );
  }

  getContratoInfo() {
    return this.httpClient.get<RequestResponse<ContratoInformationResponse[]>>(
      `${environment.apiUrl}portal/contracts/${this.companySelected?.id}`
    );
  }

  getFormationDocumentsInfo() {
    return this.httpClient.get<RequestResponse<FormationInformationResponse[]>>(
      `${environment.apiUrl}portal/formation_documents/${this.companySelected?.id}`
    );
  }

  getBalanceInfo() {
    return this.httpClient.get<RequestResponse<BalanceInformationResponse[]>>(
      `${environment.apiUrl}portal/balances/${this.companySelected?.id}`
    );
  }

  getBienesInfo() {
    return this.httpClient.get<RequestResponse<BienesInformationResponse[]>>(
      `${environment.apiUrl}portal/assets/${this.companySelected?.id}`
    );
  }

  getStatuteInfo() {
    return this.httpClient.get<RequestResponse<StatuteInformationResponse[]>>(
      `${environment.apiUrl}portal/estatuto/${this.companySelected?.id}`
    );
  }

  getDocumentsInfo() {
    return this.httpClient.get<RequestResponse<StatuteInformationResponse[]>>(
      `${environment.apiUrl}portal/estatuto/${this.companySelected?.id}`
    );
  }

  getSectionByID(section: SectionsId): CompanyPermission | undefined {
    const sectionShared = this.companySelected?.sections.find(
      (sectionfilter) => sectionfilter.id === section
    );
    return sectionShared;
  }
}
