/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { AmplitudeService } from '../shared/services/amplitude.service';
import { CompaniesService } from '../modules/companies/services/companies.service';
import { Router } from '@angular/router';
import { Users } from '../modules/user/models/users';

const API_USERS_URL = `${environment.authUrl}`;
const API_URL = `${environment.apiUrl}`;
const IS_DEVELOP = `${environment.develop}`;

@Injectable({
  providedIn: 'root',
})
export class ExternalAuthService {
  token: any;
  private currentUser: any;
  private currentUserInfo: any;
  private currentUserInfoROLE: any;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentUserSubject: BehaviorSubject<Users | undefined>;

  constructor(
    private http: HttpClient,
    private amplitudeService: AmplitudeService,
    private router: Router,
    private companiesService: CompaniesService
  ) {
    this.currentUserSubject = new BehaviorSubject<Users | undefined>(undefined);
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable((observer) => {
      const url_string = window.document.location.href;
      const url = new URL(url_string);
      const key4Token = url.searchParams.get('t');

      if (key4Token) {
        sessionStorage.clear();
      }

      this.token = sessionStorage.getItem('paramT');
      if (!this.token) {
        this.getTokenByKey().subscribe((token) => {
          this.getUserInfoByToken(token).subscribe((userInfo) => {
            this.getUserbyId(userInfo.id).subscribe((user) => {
              observer.next(true);
              observer.complete();
            });
          });
        });
      } else {
        if (!this.currentUserInfo) {
          this.getUserInfoByToken(this.token).subscribe((userInfo) => {
            this.getUserbyId(userInfo.id).subscribe((user) => {
              observer.next(true);
              observer.complete();
            });
          });
        } else {
          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  getTokenByKey() {
    sessionStorage.clear();
    const url_string = window.document.location.href;
    const url = new URL(url_string);
    const key4Token = url.searchParams.get('t');

    return this.http
      .get(`${API_URL}tokens/${key4Token}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: any) => {
          if (res.success) {
            this.token = res.result;
            sessionStorage.setItem('paramT', this.token);
            return res.result;
          } else {
            this.logout('error get token success false' + res.result);
            throw new Error('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get token' + err);
          return throwError('Error');
        })
      );
  }

  getUserInfoByToken(token: any) {
    return this.http
      .get(`${API_USERS_URL}userinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: any) => {
          this.currentUserInfoROLE = res;
          this.amplitudeService.setActionEvent('Login Portal', {
            email: res.email,
          });
          return res;
        }),
        catchError((err) => {
          this.logout('error get userInfo by token' + err);
          return throwError('Error');
        })
      );
  }

  getUserbyId(id: string) {
    return this.http
      .get(`${environment.apiUrl}portal/users/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: any) => {
          if (res.success) {
            this.currentUser = res.result;
            this.userInfoSeted(res.result);
            this.amplitudeService.setLoggedUser(res.result);
            return res.result;
          } else {
            this.logout('error get user by ID success false' + res.result);
            throw new Error('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get user by ID' + err);
          return throwError('Error');
        })
      );
  }

  logout(info?: any): void {
    console.warn('Logout', info , IS_DEVELOP);
    sessionStorage.removeItem('paramT');
    // eslint-disable-next-line eqeqeq
    if (IS_DEVELOP == 'true') {
      console.log('Development mode: Logout initiated');
    } else {
      window.location.href = environment.authLogin;
    }

    window.location.href = environment.authLogin;

  }

  getKeyTokenToDashboard() {
    const token = sessionStorage.getItem('paramT');
    return this.http
      .post(`${API_URL}tokens/${token}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.result;
          } else {
            this.logout('error get ID to dashboard' + res.result);
            return throwError('Error');
          }
        }),
        catchError((err) => {
          this.logout('error get ID to dashboard' + err);
          return throwError('Error');
        })
      );
  }

  goToDashboard() {
    this.getKeyTokenToDashboard().subscribe((res: any) => {
      sessionStorage.clear();
      window.location.href = `${environment.ppdURL}companies/list?t=${res}`;
    });
  }

  principal() {
    this.companiesService.companySelected = undefined;
    this.router.navigate(['/home-page'], {
      queryParams: {},
    });
  }

  userInfoSeted(info: any) {
    this.currentUserSubject = new BehaviorSubject<Users | undefined>(info);
    this.currentUserInfo = info;
  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: Users) {
    this.currentUserSubject.next(user);
  }

  getCurrentUserInfo() {
    return this.currentUserInfo;
  }

  getCurrentUserInfoROLE() {
    return this.currentUserInfoROLE;
  }

  getCurrentUser() {
    return this.currentUser;
  }
}
