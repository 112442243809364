import { Component, OnInit } from '@angular/core';
import { DocsSectionService } from './docs-section.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DownloadFileService } from 'src/app/shared/services/download-file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents-page',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss'],
})
export class DocumentsPageComponent implements OnInit {
  columnsTable: any[] = [
    {
      id: 'name',
      caption: 'Nombre',
      type: 'regular',
    },
    {
      id: 'download',
      caption: 'Descargar',
      type: 'icon-action',
      icon: 'file_download',
    },
  ];

  docs: any[] = [];
  page = 1;
  size = 5;
  total = 0;

  currentFolder: { name: string; herarchy: string } | null = null;

  constructor(
    private docsService: DocsSectionService,
    private snackbar: MatSnackBar,
    private downloadFileService: DownloadFileService
  ) {}

  ngOnInit(): void {
    this.loadDoscs();
  }

  downloadFile(file: any) {
    this.docsService
      .downloadFile(file.name, file.content_type, file.id)
      .subscribe((res) => {
        this.snackbar.open('Documento descargado', 'Cerrar', {
          duration: 5000,
        });
        if (res) {
        } else {
          this.snackbar.open(
            'Ha ocurrido un error al descargar documento',
            'Cerrar',
            {
              duration: 5000,
            }
          );
        }
      });
  }

  download(file: any) {
    const newApi = `${environment.apiUrl}portal/file/${file.id}`;
    this.downloadFileService
      .downloadFile(newApi, file.name, file.content_type)
      .subscribe((response) => {
        if (response) {
          this.downloadFileService
            .downloadFileError(newApi)
            .subscribe((res) => {
              if (res) {
                const errorMessage = res.result
                  ? res.result
                  : res.message?.user
                  ? res.message?.user
                  : '';
                this.snackbar.open(
                  errorMessage !== ''
                    ? errorMessage
                    : 'Ha ocurrido un error. Contacte al administrador',
                  'Cerrar',
                  {
                    duration: 5000,
                  }
                );
              }
            });
        }
      });
  }

  navigateTo(folder: any) {
    this.currentFolder = {
      name: folder.folder_title,
      herarchy: folder.folder_hierarchy,
    };
    this.loadDocsByFolder(this.currentFolder);
  }

  loadDocsByFolder(folder: any) {
    this.docsService
      .navigateToFolder(folder.herarchy, this.page, this.size)
      .subscribe((res) => {
        this.docs = res;
      });
  }

  loadDocsBypage(event: any) {
    this.size = event.pageSize;
    this.page = event.pageIndex + 1;

    if (this.currentFolder) {
      this.loadDocsByFolder(this.currentFolder);
    } else {
      this.loadDoscs();
    }
  }

  loadDoscs() {
    this.currentFolder = null;
    this.docsService
      .getDocsByCompany('id', this.page, this.size)
      .subscribe((res) => {
        this.docs = res.data;
        this.total = res.total;
      });
  }

  getIcon(type: string, contentType: string): string {
    if (type === 'folder') {
      return 'assets/folder-color.svg';
    } else {
      switch (contentType) {
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
          return 'assets/xlsx-icon.svg';
        case 'image/jpeg':
        case 'image/png':
        case 'image/jpg':
          return 'assets/image-icon.svg';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'assets/docs-icon.svg';
        case 'application/pdf':
        default:
          return 'assets/pdf-icon.svg';
      }
    }
  }
}
