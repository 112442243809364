import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Users } from "src/app/modules/user/models/users";
//import { AmplitudeClient } from "amplitude-js";
//import amplitude from "amplitude-js";
//import * as amplitude from 'amplitude-js'

@Injectable({
  providedIn: "root",
})
export class AmplitudeService {
   //private instance: AmplitudeClient;
   private instance: any;

  constructor() {
  //  const amplitude = require('amplitude-js');
  const amplitude = require('amplitude-js').default
    this.instance = amplitude.getInstance();
    // this.instance = amplitude.getInstance();
    this.instance.init(environment.amplitudeApiKey);
  }

  setLoggedUser(user: Users): void {
    if (user) {
      const userProperties: any = {
        country_id: user.country_id,
        country_name: user.country_name,
        firm: user.firm_name,
        email: user.email,
        production: environment.production,
        name: user.name,
        lastname: user.last_name,
        user_created_at: user.creation_date,
        firm_created_at: user.firm?.creation_date
      };
      this.instance.setUserId(user.id);
      this.instance.setUserProperties(userProperties);
    }
  }

  setButtonMenuClicked(properties: any): void {
    this.instance.logEvent("Menú", properties);
  }

  setActionEvent(eventName: string, properties: any): void {
    this.instance.logEvent(eventName, properties);
  }
}
