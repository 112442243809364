import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(private http: HttpClient) { }

  downloadFile(
    path: string,
    filename: string,
    contentType: string = 'application/pdf'
  ): Observable<any> {
    return this.http.get(path, {
      responseType: 'blob',
      headers: { authorization: `Bearer` }
    })
      .pipe(
        catchError((err) => {
          console.error('Download file', err);
          return of({ id: undefined });
        }),
        map((response: any) => {
          if (response && response.size > 5000) {
            const file = new window.Blob([response], {
              type: response?.type ? response?.type : contentType,
            });

            const downloadAncher = document.createElement('a');
            downloadAncher.style.display = 'none';

            const fileURL = URL.createObjectURL(file);
            downloadAncher.href = fileURL;
            downloadAncher.download = this.getFileName(response.type, filename);
            downloadAncher.click();
            downloadAncher.remove();
          }
          else {
            return response;
          }
        })
      );
  }

  downloadFileError(
    path: string
  ): Observable<any> {
    return this.http.get(path, {
      headers: { authorization: `Bearer` }
    })
      .pipe(
        map((response: any) => (response))
      );
  }

  getFileName(type: string, name: string) {
    switch (type) {
      case 'text/csv':
        return name + '.csv';
      case 'application/msword':
        return name + '.doc';
      case 'image/jpeg':
        return name + '.jpeg';
      case 'image/png':
        return name + '.png';
      case 'application/pdf':
        return name + '.pdf';
      case 'application/vnd.ms-powerpoint':
        return name + '.ppt';
      case 'application/zip':
        return name + '.zip';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return name + '.docx';
      case 'application/vnd.ms-excel':
        return name + '.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return name + '.xlsx';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return name + '.xlsx';
      default:
        return 'report.pdf';
        break;
    }
  }
}
