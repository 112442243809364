<div class="table-responsive angular-bootstrap-table">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="table table-vertical-center
            overflow-hidden table-head-custom" *ngIf=!loading>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <ng-container *ngFor="let col of columns" [matColumnDef]="col.caption">
                <th mat-header-cell *matHeaderCellDef
                    [ngClass]=" col.type === 'icon-action' ? 'maxwid100' : (col.largeCol ? 'col-md-5' : '')"
                    class="ml-3">
                    <ng-container *ngIf="col.type !== 'selection'">
                        {{col.caption}}
                    </ng-container>
                    <ng-container *ngIf="col.type === 'selection'" class="selection-button">
                        <mat-checkbox (change)="$event ? masterToggle() :
                            null" [checked]="selection?.hasValue() && allSelected"
                            [indeterminate]="selection?.hasValue() && !allSelected">
                        </mat-checkbox>
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="largeTable ? 'mat-column' : ''">
                    <ng-container [ngSwitch]="col.type">
                        <span *ngSwitchCase="columnTypes.selection">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? handleSelection(element) :
                            null" [checked]="selection?.isSelected(element)">
                            </mat-checkbox>
                        </span>
                        <span *ngSwitchCase="columnTypes.date">
                            {{ element[col.field] | date: 'dd/MM/yyyy'}}
                        </span>
                        <span *ngSwitchCase="columnTypes.regular" class="d-flex align-items-center">
                            <ng-container *ngIf="col.uppercase">
                                {{ element[col.field] | uppercase }}

                            </ng-container>
                            <ng-container *ngIf="!col.uppercase">
                                {{ element[col.field] }}
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="columnTypes.detailed" class="d-flex align-items-start">
                            <ng-container>
                                {{ element[col.field] }}
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="columnTypes.checkbox">
                            <ng-container *ngIf="element[col.field] === true">
                                <mat-icon class="material-icons light-grey">check</mat-icon>
                            </ng-container>
                        </span>

                        <!--                        <span *ngSwitchCase="columnTypes.combination" class="d-flex align-items-center">-->
                        <!--                             <ng-container>-->
                        <!--                                {{ element[col.field] }} {{ element[col.fieldOfCombination]  }}-->
                        <!--                            </ng-container>-->
                        <!--                        </span>-->
                        <!--                        <span *ngSwitchCase="columnTypes.link">-->
                        <!--                          <a class="link" [routerLink]="col.linkToRedirectTo + (col.fieldOfRedirection !== '' ? element[col.fieldOfRedirection]: '')">-->
                        <!--                             {{ element[col.field]  }}-->
                        <!--                          </a>-->
                        <!--                        </span>-->
                        <!--                        <span *ngSwitchCase="columnTypes.iconLink" class="ml-3">-->
                        <!--                             <a class="link" [routerLink]="col.linkToRedirectTo + (col.fieldOfRedirection !== '' ? element[col.fieldOfRedirection]: '')">-->
                        <!--                                <mat-icon class="material-icons light-grey">{{col.icon}}</mat-icon>-->
                        <!--                            </a>-->
                        <!--                        </span>-->
                        <span *ngSwitchCase="columnTypes.iconAction" class="ml-3">
                            <a (click)="actClicked(col.actionType , element)">
                                <mat-icon [ngStyle]="{'color': col.iconColor}"
                                    class="material-icons light-grey">{{col.icon}}</mat-icon>
                            </a>
                        </span>

                        <span *ngSwitchCase="columnTypes.iconMultiAction">
                            <span *ngFor="let action of col.multiActionIcons">
                                <a *ngIf="action.link && !action.conditional"
                                    [routerLink]="action.link + element[action.fieldOfRedirection]"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon"
                                        class="material-icons light-grey">{{action.icon}}</mat-icon>
                                    <span *ngIf="action.svgPath" inlineSVG="col.svgPathOn" [cacheSVG]="true"></span>
                                </a>
                                <a *ngIf="action.actionType && !action.conditional"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon"
                                        class="material-icons light-grey">{{action.icon}}</mat-icon>
                                    <span *ngIf="action.svgPath" inlineSVG="col.svgPathOn" [cacheSVG]="true"></span>
                                </a>
                                <a *ngIf="action.actionType && action.conditional && element[action.fieldOfCondition]"
                                    (click)="actClicked(action.actionType , element)">
                                    <mat-icon *ngIf="action.icon"
                                        class="material-icons light-grey">{{action.icon}}</mat-icon>
                                    <span *ngIf="action.svgPath" inlineSVG="col.svgPathOn" [cacheSVG]="true"></span>
                                </a>
                                <a *ngIf="action.link && action.conditional && element[action.fieldOfCondition]"
                                    [routerLink]="element[action.link] + element[action.fieldOfRedirection]"
                                    (click)="actClicked(action.actionType, element)">
                                    <mat-icon *ngIf="action.icon"
                                        class="material-icons light-grey">{{action.icon}}</mat-icon>
                                    <span *ngIf="action.svgPath" inlineSVG="col.svgPathOn" [cacheSVG]="true"></span>
                                </a>
                            </span>
                        </span>
                        <span *ngSwitchCase="columnTypes.regularWMulticolorIcons" class="d-flex align-items-center">
                            <mat-icon class="material-icons icon-size"
                                [ngStyle]="{'color': col.colors[element[col.colorField ?? 'color'] ?? ''] ?? ''}"
                                size="large">{{col.icon}}</mat-icon>
                            {{ element[col.field]}}
                        </span>
                        <span *ngSwitchCase="columnTypes.toggleSvgIcon">
                            <button mat-icon-button (click)="actClicked(col.actionType ,element)">
                                <span *ngIf="element[col.field]" inlineSVG="col.svgPathOn" [cacheSVG]="true"></span>
                                <span *ngIf="!element[col.field]" inlineSVG="col.svgPathOff" [cacheSVG]="true"></span>
                            </button>
                        </span>
                    </ng-container>
                </td>
            </ng-container>


        </table>
        <div class="empty-response" [ngStyle]="{height: (pageSize * 3).toString() + 'rem'}"
            *ngIf="dataSource?.length === 0 && !loading">
            <mat-icon class="material-icons icon-size" size="large">search_off</mat-icon>
            No hay registros en {{ tableCaption }}
        </div>
        <div *ngIf="loading" class="spinner-container" [ngStyle]="{height: (pageSize * 5.5).toString() + 'rem'}">
            <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <ng-content select="[paginator]"></ng-content>
    </div>
</div>