import { Component, OnInit } from '@angular/core';
import {Users} from '../models/users';
import { ExternalAuthService } from 'src/app/auth/external-auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  currentUser?: Users;

  constructor(private auth: ExternalAuthService) {}

  ngOnInit(): void {
    this.currentUser = this.auth.currentUserSubject.getValue();
  }

}

