import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalAuthGuard } from './auth/external-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ExternalAuthGuard],
    loadChildren: () =>
      import('./pages/main-page/main-page.module').then((m) => m.MainPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
