<div class="container">
    <div class="section">
        <h5 class="section-title">Documentos: </h5>
        <div *ngIf="currentFolder" class="back-btn">
            Carpeta actual: {{currentFolder.name}} <br>
            <div class="back" (click)="loadDoscs()"><mat-icon>chevron_left</mat-icon> Volver a carpeta principal</div>
        </div>
        <div>
            <table class="docs-table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of docs">
                        <td class="doc-name">
                            <div class="doc-item">
                        <img [src]="getIcon(doc.type,doc.content_type)" class="doc-icon" alt="icon">

                                <span>{{ doc.title }}</span>
                            </div>
                        </td>
                        <td class="download-icon" *ngIf="doc.type !== 'folder'" (click)="download(doc)">
                            <mat-icon>download_file</mat-icon>
                        </td>

                        <td class="download-icon" *ngIf="doc.type === 'folder'" (click)="navigateTo(doc)">
                            <mat-icon>chevron_right</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <mat-paginator [length]="total" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="loadDocsBypage($event)"></mat-paginator>
    </div>
    <div class="d-flex justify-content-center w-100 mt-5" *ngIf="!docs">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</div>