/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocsSectionService {
  constructor(private httpClient: HttpClient) {}

  getDocsByCompany(id: string, page: number, size: number) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}portal/library/size/${size}/page/${page}`)
      .pipe(
        map((res) => {
          if (res.success) {
            return {total : res.pagination.total , data :res.result_fm.result} ;
          } else {
            return {total : 0 , data :[]} ;
          }
        }),
        catchError((error) => {
          console.error(error);
          return of({total : 0 , data :[]});
        })
      );
  }

  downloadFile(
    filename: string,
    contentType: string = 'application/pdf',
    guid: string
  ): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}portal/file/${guid}`, {
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          console.error('Download file', err);
          return of(null);
        }),
        map((response: any) => {
          if (response.success) {
            const file = new window.Blob([response], {
              type: response?.type ? response?.type : contentType,
            });

            const downloadAncher = document.createElement('a');
            const fileURL = URL.createObjectURL(file);
            downloadAncher.href = fileURL;
            downloadAncher.style.display = 'none';

            downloadAncher.download = filename;

            downloadAncher.click();
            downloadAncher.remove();
            return true;
          } else {
            return null;
          }
        })
      );
  }

  navigateToFolder(folderHierarchy: string, start: number, limit: number) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}portal/library/${folderHierarchy}/${start}/${limit}`
      )
      .pipe(
        map((res) => {
          if (res.success) {
            return res.result.result;
          } else {
            return null;
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }
}
