/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */

export const English = {
  lang: 'EN',
  data: {
    TITLES: {
      COMPANIES: 'Companies',
      FOLLOWERS: 'Followers',
      ORIGIN_COUNTRY: 'Country of origin',
      SHARE_INFO: 'Share Information',
      LIBRARY: 'Library',
      EXPIRATIONS: 'Expirations',
      USERS: 'Users',
    },
    TABS: {
      GENERAL_INFO: 'General information',
      EXPIRATIONS: 'Expirations',
      POWERS: 'POA',
      STATUTE: 'Bylaws',
      STATUTES: 'Bylaws',
      DOCS: 'Formation documents',
    },
    LABELS: {
      COMPANY_NAME: 'Company name',
      COMPANY_TYPE: 'Company type',
      CORPORATE_DATA_CONTROL: 'Corporate Data Control',
      DOC_TYPE: 'Document type',
      DOC_NAME: 'Document name',
      DOC_DATE: 'Document date',
      PROCEDURE_TYPE: 'Procedure type',
      SELECT_DOCS: 'Select Document(s)',
      SELECT_DOC: 'Select Document',
      SELECT_PROCEDURES: 'Seleccione un tipo de trámite',
      VERIFICATION_STATUS: 'Verification Status',
      VERIFIED_BY: 'Verified by',
      NOT_VERIFY: 'Not verify',
      VERIFY: 'Verify',
      LAST_VERIFICATION: 'Last verification',
      LAST_MODIFICATION: 'Last modification',
      MODIFIED_BY: 'Modified by',
      PREVIEW: 'Preview',
      LOADING: 'Loading',
      COMPANIES: 'Companies',
      COMPANY: 'Company',
      EDIT: 'Edit',
      DELETE: 'Delete',
      SELECT: 'Select',
      USER: 'User',
      DOCUMENT_TYPE: 'Document Type',
      NAME: 'Name',
      LAST_NAME: 'Last name',
      PHONE: 'Phone',
      EMAIL: 'Email',
      ACTIONS: 'Actions',
      FOLDER: 'Folder',
      CREATE: 'Create',
      EXPIRATION_DATE: 'Expiration date',
      EXPIRATION_TYPE: 'Expiration type',
      EXPIRATION_STATUS: 'Expiration status',
      EXPIRATION: 'Expiration',
      AUTHORITY: 'Authority',
    },
    BUTTONS: {
      UPDATE: 'Update',
      GO_TO_LIBRARY: 'Go to library',
      CORPORATE_SUMMARY: 'Coporate summary',
      VERIFY: 'Verify',
      SHARE: 'Share',
      ATTACH_DOCS: 'Attach documents',
      SAVE: 'Save',
      MODIFY: 'Modify',
      NEXT: 'Next',
      CHANGE_COUNTRY: 'Change Country',
      CLOSE: 'Close',
      NEW: 'New',
      NUEVA: 'New',
      DOC: 'Document',
      DOCS: 'Documents',
      BY_DOC_NAME: 'By document name',
      BY_FOLDER_NAME: 'By folder name',
      BY_CONTENT: 'By document contents',
      UPLOAD: 'Upload',
      DELETE: 'Delete',
      DOWNLOAD: 'Download',
      MOVE_TO: 'Move to',
      ADD: 'Add',
      ALL: 'All',
      CANCEL: 'Cancel',
      RESOLVED: 'Resolved',
    },
    PLACEHOLDERS: {
      SEARCH: 'Search',
      USERS: 'Users',
      SEARCH_USER: 'Search user',
      SEARCH_COMPANY: 'Search company',
    },
    MENU: {
      LIBRARY: 'Library',
      DOCUMENTS: 'Documents',
      PERSONS: 'Persons',
      COMPANIES: 'Companies',
      EXPIRATIONS: 'Expirations',
      TRAMITES: 'Registration procedures',
      SHAREINFO: 'Share Information',
      ADD: 'Add',
    },
    PROFILE: {
      LOGOUT: 'Log out',
      USERS_SETTINGS: 'Users settings',
    },
    TABLES: {
      EMPTY: 'No records to display',
    },
    TEXT: {
      CHOOSE_COUNTRY: "Choose the company's country",
      ADD_CUSTOM_EXPIRATION: 'Add custom expiration',
      EDIT_CUSTOM_EXPIRATION: 'Edit custom expiration',
      INVALID_SELECTION: 'Invalid selection',
      SURE_TO_DELETE: ' Are you sure you want to remove the expiration?',
      SELECT_MODULE_ERROR: 'Select at least one section',
    },

    // SETTING BY COUNTRY CODE
    BY_COUNTRY: {
      AR: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Address',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
        },
      },
      DF: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          FOREING_ADDRESS: 'Domicilio',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
        },
      },
      CL: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          TYPE: 'Sede Social',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
        },
      },
      PY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUC',
          LEGAL_DOCUMENT_TYPE: 'RUC',
          STREET: 'Calle',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          ADDRESS: 'Calle',
          TYPE: 'Domicilio',
          PROVINCE: 'Departamento',
          LOCATION: 'Ciudad',
          FOREING: 'Domicilio Local',
        },
      },
      US: {
        INFO: {
          DOCUMENT_TYPE: 'TAX number',
          FILLING_NUMBER: 'Filling Number',
          REGISTERED_AGENT: 'Registered Agent',
        },
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
        UBICATION: {
          ADDRESS: 'Address',
          TYPE: 'Principal place of business',
          FOREING_ADDRESS: 'Principal place of business',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
        },
      },
      UY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'No. de puerta',
          ADDRESS_NUMBER: 'No. de Apartamento',
        },
        UBICATION: {
          TYPE: 'Domicilio',
          PROVINCE: 'Departamento',
          LOCATION: 'Localidad',
          FOREING: 'Domicilio',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
      },
    },
  },
};
