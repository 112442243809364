import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { WelcomeComponent } from '../modules/user/welcome/welcome.component';
import { TableComponent } from './components/table/table.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { NumberFormatPipe } from './pipes/number-format.pipe';

@NgModule({
  declarations: [
    WelcomeComponent,
    TableComponent,
    NumberFormatPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    BootstrapModule,
    MaterialModule,
    InlineSVGModule,
  ],
  providers: [],
  exports: [
    BootstrapModule,
    WelcomeComponent,
    CommonModule,
    TableComponent,
    MaterialModule,
    NumberFormatPipe
  ]
})
export class SharedModule {
}
