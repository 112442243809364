/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { ExternalAuthService } from "./external-auth.service";

@Injectable({
  providedIn: "root",
})
export class ExternalAuthGuard implements CanActivate {
  constructor(private externalAuthService: ExternalAuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.externalAuthService.isAuthenticated();
  }
}
