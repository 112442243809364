import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashScreenComponent } from './splash-screen.component';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  declarations: [SplashScreenComponent],
  imports: [CommonModule, InlineSVGModule],
  exports: [SplashScreenComponent],
})
export class SplashScreenModule {}
